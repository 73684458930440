<template>
<!-- 淘京拼商城订单 -->
    <div class="item">
        <div class="title">
                <div class="left" >{{ ['淘宝','拼多多','京东'][data.type-1] }}</div>
              
                <div class="right" v-if="Number(data.isPay)==0 && (data.status - 0 != 5)">待支付</div>
                <div v-else  class="right"> {{ [ '', '待发货' , '待收货' , '' ,'已完成' , '已取消' , '已关闭'][data.status-0]}}</div>
               
              </div>
              <div class="good-info">
                  <div class="imgs">
                <img :src="data.picUrl" alt="" />
               
                </div>
                <div class="good-box">
                  <div class="name">
                  {{data.spuName.length>20?data.spuName.slice(0,20)+'...':data.spuName}}
                  </div>
                  <div class="order-info">
                    <div>订单创建：{{data.createTime}}</div>
                    <div>确认收货：--</div>
                    <div>订单编号：{{data.orderNo}}</div>
                  </div>
                </div>
              </div>
              <div class="payment">
               <div class="left">付款：
                <div>￥<span>{{data.paymentPrice}}</span></div></div>
                <div class="right">赚：￥<span>{{data.commissionCashback}}</span></div>
              </div>
              <!-- <div class="bottom">
                  <div class="left">
                      下单时间：2021-08-04 18:45
                  </div>
                  <div class="right">合计：<div>￥<span>45</span></div></div>
              </div> -->
              <!-- <div class="btns">
                  <div class="btn1">
                      取消订单
                  </div>
                  <div class="btn2">
                      付款
                  </div>
              </div> -->
    </div>
</template>

<script>

import '../../assets/css/order.less'
    export default {
        props:['data']
    }
</script>

<style lang="less" scoped>


       
        .good-info{
            display: flex;
            align-items: center;
            img{
                width: 2.133333rem;
                height: 2.133333rem;
                margin-right: .266667rem;
            }
            .good-box{
                font-size: .32rem;
                color: #333;
                .name{
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    margin-bottom: .133333rem;
                }
                .order-info{
                    color: #999;
                    font-size: .32rem;
                }
            }
           
        }
         .payment{
                display: flex;
                align-items: flex-end;
                margin-top: .346667rem;
                justify-content: space-between;
                font-size: .373333rem;
                 font-weight: 500;
                .left{
                    display: flex;
                    align-items: flex-end;
                    div{
                        color: #A80000;
                       
                        span{
                            font-size: .48rem;
                            vertical-align: bottom;
                            line-height: .633333rem;
                        }
                    }
                }
                .right{
                    display: flex;
                    align-items: flex-end;
                    color: #A80000;
                    span{
                        font-size: .48rem;
                        line-height: 0.633rem;
                    }
                }
            }
            

</style>