<template>
    <div class="item" v-if="keep">
            <div class="title">
                <div class="name" style="flex:1;">{{keep.shopName}}</div>



                <div v-if="!(keep.isPay - 0)&&keep.status!=='5'">
                            <div class="right"   v-if="timer">待付款</div>
                            <div class="right"   v-if="!timer">支付超时</div>
                </div>




                

                <div class="right" v-if="keep.status==='1' &&keep.orderRefundStatus!='11'" >待使用</div>
                                <div v-if="['3','4'].includes(keep.status)">
                                <div class="right" v-if="['0',null].includes(keep.appraisesStatus)">待评价</div>
                                <!-- <div class="right" v-if="keep.appraisesStatus - 0!= 0" >已评价</div> -->
                                <div class="right" v-if="keep.appraisesStatus - 0 !=0 &&keep.status==4" >已完成</div>
                </div>

                 <div class="right" v-if="keep.orderRefundStatus =='11'" >退款完成</div>


                <div v-if="keep.spuRefundStatus!=='0'">

                            
                </div>
                 
               <div v-if="keep.status==='5'">   <div class="right"  >已取消</div>  </div>
                

                <div v-if="keep.status==='6'">  <div class="right"  >已关闭</div> </div>

                      
               
            </div>

            <div class="good-info">
                            <img :src="keep.picUrl" alt="" @click="fetchDetail()">


                            <div class="good-box"  @click="fetchDetail()">

                                                <div class="name">  服务：{{keep.spuName}}       </div>
                                                
                                            
                                                <div class="name">  总价：{{keep.paymentPrice}}  </div>
                                                
                                            
                                                <div class="name">  项目：{{keep.specInfo}}      </div>
                                                
                                            
                                                <!-- <div class="name">  预约时间：日常保洁            </div> -->
                                
                            
                                
                            </div>


                            <div class="box" style="justify-content:flex-end;padding-right:.533333rem;font-size:0.32rem;color:#ff9800">

                                                <div class="right" v-if="keep.orderRefundStatus==='1' ">退款中</div>

                                                <!-- <div class="right" v-if="keep.orderRefundStatus==='11' ">已退款</div> -->

                                                <div class="right" v-if="keep.orderRefundStatus==='12' ">退款失败</div>



                            </div>
            </div>
             <div class="btns">

                            <div class="btn1" v-if="!( keep.isPay - 0 ) && keep.status != '5'" @click="remove()">
                                取消订单
                            </div>
                            <!-- (keep.appraisesStatus-0)  || -->
                            <div class="btn1" v-if="   keep.status=='5' " @click=" deleteOne" >
                                删除订单
                            </div>


                           
                            <div class="btn2" v-if="  !(keep.status -0) && keep.status!=='5'&&timer"  @click="showPay()">
                                付款
                            </div>

                            <div class="btn1" v-if=" keep.isPay == '1' && [null,'0'].includes(keep.orderRefundStatus)&&keep.status != 4 && keep.paymentPrice" @click="refund()"  >
                                申请退款
                            </div>
                             <div class="btn1" v-if="  keep.orderRefundStatus - 0 != 0"   @click="refundDetail()">
                                查看详情
                            </div>


                            <div class="btn1" v-if="['0',null].includes(keep.appraisesStatus)&& ['3','4'].includes(keep.status)"
                            
                            @click="$router.push({  path : 'evaluate' ,query : {id : keep.id,shopId : keep.shopId ,shopName:keep.shopName} })" >
                                去评价
                            </div>
                  
              </div>
    </div>
</template>

<script>
import {cancel} from '../../api/cancelOrder/index'

    export default {
        props:['keep','type','index'],
        data(){
            return{
                timer:null,
                timerId:0
            }
        },
          created(){
            this.getTime()
        },
        methods:{
             getTime(){

                 if( this.keep.status ){ 
                     return
                 }
                if(  ( Date.now() - ( new Date ( this.keep.createTime.replace(/-/ig,'/') ).getTime() + 15 * 60 * 1000 ) ) < 0 ){

                        this.timer = true

                }else{

                        this.timer = false

                return

                }
            
              
                      this.timerId =  setInterval(()=>{
                            if(  (Date.now()-(new Date(this.keep.createTime.replace(/-/ig,'/')).getTime()+15*60*1000))  <0 ){
                              this.timer=true
                            }else{
                              this.timer=false
                               clearInterval(this.timerId)

                            }
                           
                      },1000)

           },
            remove(){
                
                cancel(this.keep.id).then(res=>{
                    if(res.data.code===0){
                        this.$toast.success('取消成功')

                        if(this.type != 0){ 
                            this.keep.type = 1000
                        }
                        this.keep.status = '5'
                        clearInterval( this.timerId )
                    }
                })
            },
            deleteOne(){ 
                this.deleteOrder( { id :this.keep.id , type : '1' } ).then ( res => {  
                    if( res.data.code == 0 ) { 
                        this.$toast.success('删除成功')
                        this.$set(this.keep , 'type' , '1000')
                        return
                    }
                    this.$toast.success('删除失败')
                })
            },
            showPay(){
              let data={
                name:'mall',
                orderId:this.keep.id,
                price: this.keep.paymentPrice
              }
              this.$emit('onShow',data)
              sessionStorage.setItem('mall',JSON.stringify({att:'status',status:1,index:this.index}))
            },
             refund(){
             this.$router.push({
                path:'refund',
             query:{
             orderId:this.keep.id,
                orderItemId:this.keep.orderItemId,
                orderType:'0',
                status:1,
                index:this.index,
                attribute:'spuRefundStatus'
            

       }
     })
   },
   fetchDetail(){

        this.$router.push({ 
                path    :'/keepOrder',
                query   :{ 
                    id:this.keep.id
                }
            })

   },
    refundDetail(){

        
        if(this.keep.refundStatusId){ 
         this.$router.push({
       path:'refundDetail',
       query:{
         id:this.keep.refundStatusId,
         refundId:Number(this.keep.orderRefundStatus.split('')[0]) + Number((this.keep.orderRefundStatus.split('')[1])?? 0) 
       }
     })
        }else{ 

            this.$router.push({ 
                path:'/keepOrder',
                query:{ 
                    id:this.keep.id
                }
            })
        }
   
   },
        }
       
    }
</script>

