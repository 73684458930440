<template>
<!-- 自营商城 -->
    <div class="item" >
         <div class="title">
              
                <div class="left">{{mall.shopName}}</div>
                <div class="right" v-if="mall.status==null && mall.isPay==='0'&&timer">待付款</div>
                <div class="right" v-if="mall.status==null && mall.isPay==='0'&&!timer">已过期</div>
                <div class="right" v-if="mall.status==='1' && mall.isPay==='1' &&mall.orderRefundStatus!='11'">待发货</div>
                <div class="right" v-if="mall.status==='2' &&mall.orderRefundStatus!='11'">待收货</div>
                <div class="right" v-if="mall.status==='4'&& ['0',null].includes(mall.appraisesStatus)">待评价</div>
                <div class="right" v-if="mall.status==='4'&&  !['0',null].includes(mall.appraisesStatus)  ">已完成</div>
                <!-- <div class="right" v-if="mall.status">待评价</div> -->
                
                <!-- <div class="right" v-if="mall.status==='4'&&mall.appraisesStatus==='1'">已评价</div> -->
                <div class="right" v-if="mall.status==='5' && ! mall.orderRefundStatus-0">已取消</div>
                <div class="right" v-if="mall.status==='6'">已关闭</div>
                <div class="right" v-if="mall.status==='10' &&mall.orderRefundStatus!='11'">拼团中</div>
                <div class="right" v-if="mall.orderRefundStatus==='11'">退款完成</div>


              </div>
              <div class="good-info" v-for=" li , index in mall.orderItemList" :key=" li.picUrl + li.id " :class="{ border:mall.orderItemList.length != index+1 }" >
                <img
                @click="fetchDetail()"
                  :src="li.picUrl"
                  alt=""
                />
                <div class="good-box" @click="fetchDetail()">
                      <div class="name van-multi-ellipsis--l2" >  {{li.spuName}}</div>
                       <!-- <div class="order-info">
                        <div>数量：{{li.quantity}}</div>
                      </div>
                      <div class="order-info"> <div>单价：{{li.salesPrice}}元</div> </div> -->
                       
                     
                      
                      <div class="order-info"><div>规格：{{li.specInfo||'无'}}</div> </div>
                        
                     
                </div>
                  <!-- <div class="refund" >
                 
                <div>申请退款</div>
         
                </div> -->
                
                <div class="good-price" v-if="li.refundStatus -0  " @click="lookRefund(li)">
                 
                            <div class="right" v-if="li.refundStatus ==  '1' ">  退款中    </div>
                            <!-- <div class="right" v-if="li.refundStatus ==  '11'">  退款成功  </div> -->
                            <div class="right" v-if="li.refundStatus ==  '12'">  退款失败  </div>

         
                </div>
                 <div class="good-price" v-if="li.status == 4 || mall.status == 4  " >
                 
                 
                            <div class="right" v-if="li.appraisesStatus ==  null " 
                            @click="$router.push({ 
                  path:'mallEvaluate', query:{   id:mall.id,shopId:li.shopId,orderItemId:li.id }  })">  去评价    </div>
                           

                <div v-else> 已评价</div>
                </div>
                <div class="good-price" v-if="!(mall.orderRefundStatus-0) ">
                 
                 <div style="opacity:0">占位使用</div>

         
                </div>
              </div>
              <div class="payment">
              共{{numAll}}件商品 合计：<div>￥ <span>{{mall.paymentPrice}}</span> </div>
              </div>
              <div class="btns">
                    

                  <div class="btn1" v-if=" !(mall.isPay - 0 ) && mall.status != '5' " @click="remove()">
                      取消订单
                  </div>


<!-- ||  (mall.appraisesStatus - 0) || mall.orderRefundStatus ==  11 -->
                  <div class="btn1" v-if="mall.status == '5' " @click="deleteOne()">
                      删除订单
                  </div>


                   <div class="btn1" v-if=" isTrue  &&  (mall.isPay - 0 ) && mall.status != 4 " @click="$router.push({path:'mallApplyRefund',query:{id:mall.id}})"  >
                      申请退款
                  </div>


                  <!-- <div class="btn1" v-if=" mall.isPay != 0 || mall.spuRefundStatus==='3'" @click="$router.push({path:'refund',query:{id:mall.orderItemId,orderType:'0',orderId:mall.id,status:'1'}})"  >
                      取消退款
                  </div> -->
                  


                  <!-- <div   v-if="mall.status==='4'&& ['0',null].includes(mall.appraisesStatus)"  class="btn1"   >
                      去评论
                  </div> -->
                   <div   class="btn1" @click="detail()" >
                      查看详情
                  </div>

                  <div class="btn2"  v-if="mall.isPay==='0'&&timer &&mall.status != 5"  @click="showPay()">
                      付款
                  </div>



                   <div class="btn2" v-if="mall.status==='2'&&!(mall.appraisesStatus - 0 )" @click="Receiving()">
                      确认收货
                  </div>
                  
              </div>

    </div>
</template>

<script>

import { Dialog } from 'vant';
import {cancel} from '../../api/cancelOrder/index'
import {receive} from '../../api/ordersCenter/index'
import '../../assets/css/order.less'
    export default {
        props:['mall','type','index'],
        data(){
            return{
              timer:'',
              timerId:0,
              numAll:0,
              
            }
        },
        mounted(){

          this.mall.orderItemList.forEach( item => { 

            this.numAll += item.quantity
          })
            this. Time()


        },
        
        methods:{
            remove(){
                cancel(this.mall.id).then(res=>{
                    if(res.data.code===0){
                        this.$toast.success('取消成功')
                        if(this.type != 0){ 
                          this.mall.type == 100
                          return
                        }
                        this.mall.status='5'
                    }
                })
            },
            deleteOne() { 

                this.deleteOrder( { id : this.mall.id , type : '1'} ).then(  () =>  { 
                    this.$set(this.mall , 'type' , '100')
                })
            },
            showPay(){
              let data={
                name:'mall',
                orderId:this.mall.id,
                price:this.mall.paymentPrice
              }
              this.$emit('onShow',data)
              sessionStorage.setItem('mall',JSON.stringify({att:'status',status:1,index:this.index}))
            },

            // 订单详情
            detail(){

             
                     this.$router.push({
                        path:'/mallorderDetail',
                        query:{
                          id:this.mall.id,
                          index:this.index
                          
                        }
                  })
                   
              
             
             
           
             
            },
            lookRefund(item){ 
               let index = item.refundStatus == 12? 2 :item.refundStatus == 11?3:1
              this.$router.push({ 
                path:'refundDetail',
                query:{ 
                  id:this.mall.refundStatusId,
                  refundId:  index
                  ,type:1
                   
                }
                
              })
            },
            fetchDetail(){ 

              this.$router.push({
                        path:'/mallorderDetail',
                        query:{
                          id:this.mall.id,
                          index:this.index
                        }
                  })
            },
           
           Time(){
             if(this.mall.status == 5 || this.mall.isPay != 0){ 
               this.timer = false
               return
             }
            if((Date.now()-(new Date(this.mall.createTime.replace(/-/ig,'/')).getTime())-15*60*1000)<0){
              this.timer=true
            }else{
              this.timer=false
            }
            
                if(this.timer){
                      this.timerId=  setInterval(()=>{
                            if(Date.now()-new Date(this.mall.createTime.replace(/-/ig,'/')).getTime()-15*60*1000<0){
                              this.timer=true
                            }else{
                              this.timer=false
                            }
                      },1000)

                }else{

                  clearInterval(this.timerId)
                }


           },
           rightReceive(){ 
             Dialog.confirm({
              title: '标题',
              message: '弹窗内容',
            }).then(()=>{ 


               receive(this.mall.id).then(res=>{ 
               if(res.data.code===0){ 
                 this.$toast.success('收货成功')
                 this.mall.status==='4'
                 if(this.type!=0){ 
                   this.mall=null
                 }
               }else{ 
                 this.$toast.fail('收获失败')
               }
             })        


            })

              
           },


          Receiving(){ 
             Dialog.confirm({
              title: '确认收货',
              message: '是否确认收货',
            }).then(()=>{ 



                receive(this.mall.id).then(res=>{ 
              if(res.data.code===0){
                 
                this.$toast.success('收货成功')
                this.$set( this.mall, 'status', '4');
                //
              }else{ 
                this.$toast.fail('收货失败')
              }
            })

            })
            
          }

        },
        computed:{ 
         isTrue: function(){return this.mall.orderItemList.some( item => item.refundStatus == null)} 

        },
        
    }
</script>


