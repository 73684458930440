import {tWXPay,tAliPay} from '../api/ordersCenter/index';
import { openPay} from './index'
import { Toast} from 'vant'
function tPay(orderNo,type,price){ 

    if(type=='2'){ 

        tAliPay({outTradeNo:orderNo,pamentPrice:price}).then(res=>{ 
            if(res.data.code != 0){ 
                Toast(res.data.msg)
                return
            }
            openPay(res.data,'2')
        })
        
    }else{ 

        tWXPay({tradeNo:orderNo}).then(res=>{ 
            if(res.data.code != 0){ 
                Toast(res.data.msg)
                return
            }
            openPay(res,'1')
        })
    }

}


export { 
    tPay
}