<template>
    <div class="item"   >
        <div class="title">
                <div class="left">{{data.shopName}}</div>
                <div style="flex:1;"></div>

                <div v-if="data.spuRefundStatus!='3'">
               <div  class="right" v-if="data.isPay==='0'&&data.status!=='5'">
                   <div class="right"   v-if="timer">待付款</div>
                   <div class="right"   v-if="!timer">支付超时</div>
               </div>
                 
                   <div class="right" v-if="data.status=='2'|| data.status== '1'" >待使用</div>
                   <div class="right" v-if="data.status==='4' && !(data.appraisesStatus - 0)" >待评价</div>
                   <div class="right" v-if="data.status==='4' && (data.appraisesStatus - 0)" >已完成</div>
                 
               <div v-if="data.status==='5'">
                   <div class="right"  >已取消</div>
               </div>
                <div v-if="data.status==='6'">
                        <div class="right"  >已关闭</div>
                </div>
                </div>

                 <div v-if="data.spuRefundStatus==='3'">
                        <div class="right"  >退款完成</div>
                 </div>

                <!-- <div class="right"   v-if="data.status==='4'">待使用</div>
                <div class="right"   v-if="data.status==='4'">待使用</div>
                <div class="right" v-if="data.status!=='4'">待使用</div> -->

              </div>
              <div class="good-info">
                <img
                @click="getDetail()"
                  :src="data.picUrl"
                  alt=""
                />
                <div class="good-box" @click="getDetail()">
                  <div class="name">
                  {{data.shopName}}
                  </div>
                  <div class="order-info">
                    <!-- <div >预定时间： {{data.showSime?data.showSime:'--'}} </div> -->
                    <div >人数：    {{data.quantity}}人/份</div>
                    <div >总价：   ¥{{data.paymentPrice}}</div>
                    
                  </div>
                  
                </div>
                <div class="box">
                       <div class="right" v-if="data.spuRefundStatus    === '1'  ">退款中   </div>
                       <!-- <div class="right" v-if="data.spuRefundStatus    === '3'  ">已退款   </div> -->
                       <div class="right" v-if="data.orderRefundStatus  === '12' ">退款失败 </div>
                  </div>
               
              </div>
              
                
                   
                    
                  
                
              <div class="btns">
                 
                  <!-- <div class="btn1" v-if="(data.spuRefundStatus=='1') || data.spuRefundStatus=='5'" @click="refund()">
                      取消退款
                  </div> -->
                  <div class="btn1" v-if="data.status != '5'&&data.isPay==='0'" @click="remove()">
                      取消订单
                  </div>
                  <!-- || data.status == 4 || data.orderRefundStatus== 11 -->
                  <div class="btn1" v-if="data.status == 5  " @click="deleteOne()">
                      删除订单
                  </div>
                  <div class="btn1" v-if="(data.isPay - 0)&& !Number(data.refundStatusId)&&data.status!=4 && data.paymentPrice" @click="refund()">
                      申请退款
                  </div>
                   <div class="btn1" v-if="data.status=='4'&& !(data.appraisesStatus- 0)" @click="$router.push({path:'evaluate',query:{id:data.id,shopId:data.shopId,shopName:data.shopName,orderItemId : data.orderItemId}})"  >
                      去评价
                  </div>
                   <div class="btn1" v-if="data.status!==null&&data.isPay==='1'" @click="getDetail()">
                      查看详情
                   </div>
                  <div class="btn2" v-if="data.status===null&&data.isPay==='0'&&timer" @click="showPay()">
                      支付
                  </div>
                  
              </div>
    </div>
</template>

<script>
import {cancel} from '../../api/cancelOrder/index'
import '../../assets/css/order.less'

    export default {
        props:['data','type','index'],
        data(){
            return{
                timer:null,
                timerId:0
            }
        },
        created(){
            this.Time()
        },
        methods:{
             getDetail(){
   
      if(Number(this.data.spuRefundStatus) !==0){ 
          this.$router.push({ 
              path:'refundDetail',
              query: { 
                  refundId:this.data.orderRefundStatus,
                  id:this.data.refundStatusId,
                  orderId:this.data.id,
                  price:this.data.paymentPrice,
                  type : 1
              } 
          })
          return
      }
        this.$router.push({
          path:'movieDetail',
          query:{
            id:this.data.id,
            type:'0'
          }
        })
      },
             refund(){
                    this.$router.push({
                    path:'refund',
                    query:{
                        orderId:this.data.id,
                        orderItemId:this.data.orderItemId,
                        orderType:'0',
                        status:'0',
                       index:this.index,
                       attribute:'spuRefundStatus'

                }
                })
            },
             remove(){
                cancel(this.data.id).then(res=>{
                    if(res.data.code===0){
                        this.$toast.success('取消成功')
                        if(this.type != 0)
                        this.data.type =1000
                       return 
                    }
                    this.data.status='5'
                })
            },

            deleteOne(){ 
                    this.deleteOrder({ id : this.data.id , type: '1' }).then( () => { 
                               
                                this.$set(this.data, 'type', '20');
                        
                      
                    })

            },
             showPay(){
              let data={
                name:'mall',
                orderId:this.data.id,
                price:this.data.paymentPrice
              }
              this.$emit('onShow',data)
              sessionStorage.setItem('mall',JSON.stringify({att:'status',status:2,index:this.index}))
            },

            
             Time(){
            if((Date.now()-(new Date(this.data.createTime.replace(/-/ig,'/')).getTime())-15*60*1000)<0){
              this.timer=true
            }else{
              this.timer=false
            }
            
                if(this.timer){
                      this.timerId=  setInterval(()=>{
                            if(Date.now()-new Date(this.data.createTime.replace(/-/ig,'/')).getTime()-15*60*1000<0){
                              this.timer=true
                            }else{
                              this.timer=false
                            }
                      },1000)

                }else{

                  clearInterval(this.timerId)
                }


           }
        }
    }
</script>

