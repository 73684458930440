<template >
<!--  酒店订单 -->
    <div class="item"   >
        <div class="title" >
                <div class="left">{{hotel.shopName}}</div>

                <div class="right" v-if="hotel.isPay==='0'&&hotel.hotelStatus==='0'&&!time" >待付款</div>
                
                <div class="right" v-if="hotel.hotelStatus=='0'&&hotel.isPay=='1' && hotel.refundStatus != 2">待入住</div>
                
                <div class="right" v-if=" hotel.refundStatus == 2">退款完成</div>
                <div v-if="hotel.hotelStatus==='1' ">

                                    <div class="right" v-if="hotel.appraisesStatus==0">待评价</div>
                                    <div class="right" v-if="hotel.appraisesStatus!=0">已完成</div>
                </div>
               

              
                <div class="right" v-if="hotel.hotelStatus==='2'">已取消</div>
                <div class="right" v-if="hotel.isPay==='0'&&(time)&&hotel.status!=2">支付超时</div>

                 </div>
                 <div class="good-info">
                <img
                v-if="hotel.picUrl" :src=" JSON.parse( hotel.picUrl)[0]"  @click.self="goCollect()"/>
                 
                <div class="good-box" @click="goCollect()">
                  <!-- <div class="name"  > </div> -->
                 
                        <div class="order-info" >
                                    <div>{{hotel.quantity}}间 <span v-if="hotel.roomName">{{','+hotel.roomName}}</span> </div>
                                    <div>{{hotel.checkinDate.slice(0,10) + '&ensp;~&ensp;' + hotel.checkoutDate.slice(0,10)}}</div>
                                    <div>总价：¥{{hotel.paymentPrice}}</div>
                        </div>
                        
                </div>
                <div class="good-price">
                        <div class="right" v-if="hotel.refundStatus==='1'">退款中</div>
                        <!-- <div class="right" v-if="hotel.refundStatus==='2'">退款成功</div> -->
                        <div class="right" v-if="hotel.refundStatus==='3'">退款失败</div>

                </div>
              </div>
                <!-- <div class="btns"  v-if="  hotel.status == '2' || (hotel.appraisesStatus-0)">
                        
                        
                </div> -->
                <div class="btns" >
                    <!-- || (hotel.appraisesStatus-0) -->
                        <div class="btn1" v-if="  hotel.status == '2' "  @click.stop="remove(true)" >
                            删除订单
                        </div>
                        <div class="btn1"  v-if="hotel.isPay==='0'&&hotel.hotelStatus==='0'" @click.stop="remove(false)" >
                            取消订单
                        </div>
                        <div class="btn1"  v-if="hotel.hotelStatus=='1' && !(hotel.appraisesStatus-0)   "   @click="$router.push({ 
                                 path:'evaluate',query:{   id:hotel.id, shopId:hotel.shopId ,type:4,shopName:hotel.shopName} })" > 去评价 </div>
                          <div class="btn1" v-if="  time || ( hotel.isPay - 0 ) "   @click="goCollect()">
                            查看详情
                        </div>
                         
                        <div class="btn2" v-if="hotel.isPay==='0'&&hotel.hotelStatus==='0'&&!time" @click="showPay()"   >
                            付款
                        </div>
                   
                      
              </div>
                 
              
    </div>
</template>

<script>
import {cancelHotel} from '../../api/cancelOrder/index'

    export default {
        props:['hotel','type','index'],
        data(){ 
            return{ 
                time:Date.now()- new Date(this.hotel.createTime.replace(/-/ig,'/')).getTime()>15*60*1000
            }
        },
        methods:{

            //取消订单
            remove(bool){

                if(bool){ 

                    this.deleteOrder({type:3,id : this.hotel.id}).then( (res) => { 

                        if(res.data.code==0) { 

                            this.$toast.success('删除成功')
                            this.hotel.hotelStatus = null
                        return

                        }else{ 
                            this.$toast.fail('删除失败')
                            return
                        }

                        
                    })

                    return
                }
                        cancelHotel(this.hotel.id).then(res=>{
                                    if(res.data.code===0){

                                                this.$toast.success('取消成功')
                                                this.hotel.hotelStatus = '2'
                                                this.hotel.status = '2'
                                                if(this.type != 0){ 
                                                    this.hotel.hotelStatus = null
                                                }
                                    }
                        })
            },
            // 订单详情
            goCollect(){
                        this.$router.push({path:'/hotelOrder',
                                                query:{ 
                                                    id      :   this.hotel.orderNo,
                                                    index   :   this.index+''
                                                }    
                    
                    })
            },
             showPay(){
              let data={

                        name    :   'hotel',
                        orderId :   this.hotel.id,
                        price   :   this.hotel.paymentPrice

              }
          
              this.$emit("onShow",data)
                sessionStorage.setItem('mall',JSON.stringify({index:this.index,att:'status',status:0}))
            },
           
        }
       
    }
</script>

