import { openPay } from '../../common'
import { wxPay,aliPay} from './index'
function moivePay(id,type,price){
    try {
        if(type==='1'){
            wxPay({id:id}).then(res=>{
                if(res.data.code===0){
                    openPay(res,type)
                }
            })

    }else{
        aliPay({outTradeNo:id,pamentPrice:price}).then(res=>{
            if(res.data.code===0){
                openPay(res.data,type)
            }
        })

    }
    } catch (error) {
        //
    }
    
}
export {
    moivePay
}