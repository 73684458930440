import { fetchPost } from '../../router/https'


export function orderTakeList(data) {

    return fetchPost('/takeout/user/order/myorder', data)
}


export function foodPay(data) {
    return fetchPost('/takeout/user/paynow', data)
}


export function cancelNotPayOrder(data) {

    return fetchPost('/takeout/user/takeoutordergoods/cancelorders', data)
}