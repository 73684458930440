<template>
    <div class="item"  >
                <div class="title">

                            <div class="left">{{data.startStation}}站 → {{data.recevieStation}}站</div>
                            <div class="right" v-show="timer&&!data.payTime&&data.status!='5'" >待支付 还剩余{{timer}}   </div>
                            <div class="right"  v-if="!data.payTime&&!timer&&data.status!='5'" >            已过期      </div>
                            <div class="right"  v-if="data.payTime && data.status==0 "  >                   预定中      </div>
                            <div class="right"  v-if="data.status == '1'" >                                 已退票      </div>
                            <div class="right"  v-if="data.status == '2'" >                                 待出行      </div>
                            <div class="right"  v-if="data.status == '5'" >                                 已取消      </div>
                            <div class="right"  v-if="data.status == '6'" >                                 退票中      </div>
                            <div class="right"  v-if="data.status == '7'" >                                 退票失败    </div>
                            <div class="right"  v-if="data.status == '9'" >                                 出票失败    </div>
                            <div class="right"  v-if="data.status == '10'" >                                已退票      </div>
                            <div class="right"  v-if="data.status == '11'" >                                已退款      </div>

                </div>
                <div class="good-info">

                            <img  src="../../assets/train.png" alt="" />
                
                            <div class="good-box">
                            
                                        <div class="order-info">

                                                    <div>发车时间：{{data.startTime}}    </div>
                                                    <div>乘坐车次：{{data.trainNo}}      </div>
                                                    <div>订单总价：¥{{data.paymentPrice}}</div>
                                            
                                        </div>

                            </div>
               
              </div>
               <div class="tip" v-if="data.status == 1">
                            <span>Tip :</span>退票成功后,退款将退回支付账户
                            <!-- 如需车票信息，请取纸制车票 -->
                </div>
              <div class="btns">
                       
                        <div class="btn1" v-if=" ['0',null].includes(data.payTime)&&data.status != 5 "  @click="cancel()"       >取消订单</div>

                        <div class="btn1" v-if="['4','9','5','1'].includes(data.status)"                @click="deleteTick()"   >删除订单</div>

                        <div class="btn2" v-if="timer && !data.payTime && data.status!='5'"             @click="showPay()"      >支付</div>

                        <div class="btn2" v-if="(data.payTime && data.status==0) || data.status == 2"   @click="refundTicket()" >退票</div>

              </div>

    </div>

</template>

<script>
// refundTicket
import { cancelTicket , delTicket  ,refundTrainTick} from '../../api/ordersCenter/index'
import { Dialog } from 'vant';
    export default {
        props:['data','type'],
        data(){ 

            return{ 
                timer:null,
                timerId:0
            }

        },
        created(){ 
            this.fetchTime()
        },
        methods:{ 

             showPay(){
              let data = {
                name    : 'travel',
                orderId : this.data.tradeNo,
                price   : this.data.paymentPrice
              }
            
              this.$emit("onShow",data)
             
            },
            
            fetchTime(){ 
                
                if( Date.now() - new Date(  this.data.createTime.replace(/-/ig,'/') ).getTime() - 15 * 60 * 1000 < 0 ){ 
                    let time   = (15 * 60 * 1000 - (Date.now()-new Date(this.data.createTime.replace(/-/ig,'/')).getTime())) / 1000

                    let min    = Math.floor(time/60) 

                    let sec    = (time -min*60)<10?'0'+ Math.floor(time -min*60):Math.floor(time -min*60)

                    this.timer = min+'分'+sec+'秒'

                    this.timerId = setInterval(()=>{ 
                                    if(this.data.status=='5'){ 

                                        clearInterval(this.timerId)
                                        this.timer=false
                                        return

                                    }
                        
                                    let time  =  (15*60*1000-(Date.now()-new Date(this.data.createTime.replace(/-/ig,'/')).getTime()))/1000 
                                    time      =      Math.floor(time)
                                    if(time<0){ 

                                            clearInterval(this.timerId)
                                            
                                            this.timer=false
                                                return
                                    }
                                    let min = Math.floor(time/60) 
                                    let sec = (time -min*60)<10?'0'+ Math.floor(time -min*60):Math.floor(time -min*60)
                                    this.timer = min+'分'+sec+'秒'
                    },1000)

                    
                }else{ 

                    this.timer=false
                }
                
            },

            cancel(){ 

                cancelTicket({id:this.data.id,state:'5'}).then(res=>{ 
                                if(res.data.code==0){ 
                                    this.$toast.success('取消成功')
                                    if(this.type != 0){ 

                                        this.data.startStation = null
                                    }
                                    this.data.status='5'
                                }else{ 
                                    this.$toast.fail('取消失败')
                                    
                                }
                })

            },

            deleteTick(){ 

                    delTicket(this.data.id).then(res=>{ 
                        if(res.data.code==0){ 
                            this.$toast.success('删除成功')
                            this.data.startStation =null
                        }else{ 
                            this.$toast.fail('删除失败')
                        }
                    })
            },
           

            refundTicket(){ 
                  Dialog.confirm({
                    title: '退票',
                    message: '是否确认退票',
                
                }).then( () => { 
                    refundTrainTick({orderNos:this.data.orderNo}).then( res => { 
                    if(res.data.code == 0){ 
                        this.$toast.success('退票成功')
                    }else{ 
                        this.$toast(res.data.msg)
                    }
                    })
                    
                }).catch( () => { 
                    //
                })
               
            }
        }
    }
</script>

<style lang="less" scoped>

 .item{
        padding: 0.586667rem 0 0.373333rem;
        background-color: white;
        margin-bottom: .266667rem;
        border-radius: .133333rem;
        padding: .266667rem;
        font-family: PingFangSC-Medium, PingFang SC;
        
        .title {
          display: flex;
          justify-content: space-between;
          font-size: 0.373333rem;
          font-weight: 500;
          margin-bottom: .48rem;
          font-size: .373333rem;
          margin-bottom: .48rem;
          .right {
            color: #ff5200;
          }
        }
        .good-info{
            display: flex;
            height: 2.133333rem;
            color: #333;
            font-size: .32rem;
            align-items: flex-start;
            img{
                width: 2.133333rem;
                height: 2.133333rem;
                margin-right: .266667rem;
                border-radius: .266667rem;
                overflow: hidden;
            }
            .good-box{
                font-size: .32rem;
                color: #333;
                div{ 
                    margin-bottom: .266667rem;
                }
                .name{
                    font-weight: 500;
                  
                }
                .order-info{
                    font-size: .32rem;
                }
            }
           
        }
         .payment{
                display: flex;
                align-items: flex-end;
                margin-top: .346667rem;
                justify-content: flex-end;
                font-size: .373333rem;
                 font-weight: 500;
                div{
                    display: flex;
                    align-items: flex-end;
                    color: #A80000;
                    span{
                        font-size: .48rem;
                        line-height: .63rem;
                    }
                }
            }
            .bottom{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: .373333rem;
                .right{
                    color: #333;
                    display: flex;
                    align-items: center;
                    div{
                        color: #A80000;
                        
                        span{
                            font-size: .48rem;
                        }
                    }
                }
            }
             .tip{ 
                    flex: 1;
                    margin: .266667rem 0;
                    font-size: .32rem;
                    transform: scale(0.9);
                    span{ 
                    color: red;

                    }
                }
            // .btns{
            //     margin-top: .533333rem;
            //     display: flex;
            //     font-size: .373333rem;
            //     align-items: center;
            //     justify-content: flex-end;
               
            //     .btn1{
            //          display: flex;
            //        align-items: center;
            //        justify-content: center;
            //          width: 2.24rem;
            //         height: .72rem;
            //         border: .026667rem solid #999 ;
            //         border-radius: .48rem;
            //         color: #999;
            //     }
            //     .btn2{
            //          display: flex;
            //        align-items: center;
            //        justify-content: center;
            //          width: 2.24rem;
            //         height: .72rem;
            //         height: .8rem;
            //         width: 2.293333rem;
            //         background-image: linear-gradient(to right , #FF7200,#FF3C00);
            //         color: white;
            //         border-radius: .48rem;
            //         margin-left: .266667rem;
            //     }
            // }
      }
      .imgs{
          overflow-y: auto;
      }
    
    
  

</style>