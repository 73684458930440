<template>
  <div class="item" >
    <div class="title">
      <div class="left">{{ movie.movieName }}</div>
      <div class="right" v-if="movie.status!='10' &&movie.isPay === '0' && time !== -1">
        待付款，剩余{{ time }}
      </div>
      <div class="right" v-if="movie.status==null&& movie.isPay === '0' && time === -1">已过期</div>
      <!-- <div class="right" v-if=" movie.isPay === '0' ">待支付</div>
      <div class="right" v-if=" movie.isPay === '0' ">已过期</div> -->

      <div class="right" v-if="movie.status==='2'&& movie.isPay === '1' ">受理中</div>
      <div class="right" v-if="movie.status==='3'&& movie.isPay === '1' ">待出票</div>
      <div class="right" v-if="movie.status==='4'&& movie.isPay === '1' ">待观看</div>
      <div class="right" v-if="movie.status==='5'&& movie.isPay === '1' ">已完成</div>
      <!-- <div class="right" v-if="movie.status!=null&&movie.isPay === '1'">待使用</div> -->
      <div class="right" v-if="movie.status!=null&&movie.isPay === '0' &&movie.status==='10' ">已取消</div>
    </div>
    <div class="good-info">
      <div class="movie-logo">
        <img :src="movie.picUrl" @click="getDetail" alt="" />
      </div>

      <div class="good-box">
        <div class="order-info" @click="getDetail">
          <div>
            {{ movie.cinemaName }} <br />
            场次：{{movie.showSime}}<br />
            数量：{{ movie.quantity }}张<br />
            总价：¥{{ movie.paymentPrice }}
          </div>
        </div>
      </div>
    </div>

    <div class="btns">
      <div class="btn1" v-if="movie.status != 10 &&movie.isPay === '0' " @click="cancel()">取消订单</div>
      <div class="btn2" v-if="movie.status===null&&movie.isPay === '0' && time !== -1" @click="pay()">付款</div>
      <div class="btn1" v-else @click="getDetail">查看详情</div>
      
       <!-- <div class="btn1"  v-if="movie.isPay!=0"  @click="getDetail" >查看详情</div> -->
    </div>
  </div>
</template>

<script>
import { cancelMoive } from "../../api/movie/index";
export default {
  props: ["movie", "type",'payType'],
  data() {
    return {
      time: "",
      timeId: "",
    };
  },
  created() {
    if (this.movie && this.movie.isPay === "0"&&this.movie.status===null) {
      this.time = this.fetchTime(this.movie.createTime, 15);
 
      if (this.time != -1) {
        this.timeId = setInterval(() => {
          this.time = this.fetchTime(this.movie.createTime, 15);
          if (this.time === -1) {
            clearInterval(this.timeId);
          }
      
        }, 1000);
      }
    }
  },
    methods:{
     
      getDetail(){
      //    if(this.movie.isPay!=='1'){
      //     return
      // }
        this.$router.push({
          path:'movieDetail',
          query:{
            id:this.movie.id,
            type:1
          }
        })
      },
        cancel(){
            cancelMoive({id:this.movie.id,status:'10'}).then(res=>{
                if(res.data.code===0){
                    this.$toast.success('取消成功')
                    this.$set(this.movie,status,'10')
                    clearInterval(this.timeId)
                  
                    
                    this.time=-1
                    if(this.type != 0){ 
                      this.movie.movieName = null
                    }
                    this.movie.status='10'
                }else{
                    this.$toast.fail("取消失败")
                }
            })

            
        },
        pay(){
            let data={
                name:'film',
                orderId:this.movie.id,
                price:this.movie.paymentPrice
            }
            this.$emit('onShow',data)
        }
    },
  destroyed() {
    clearInterval(this.timeId);
  },
};
</script>

