import { fetchPut ,fetchPost} from "../../router/https";
 function cancelMoive(data){
    return fetchPut('/mallapp/movieorder',data)
}
function wxPay(data){
    return fetchPost('/mallapp/movie/moviePayOrder',data)
}
function aliPay(data){
    return fetchPost('/mallapp/pay/appAlipayMovie',data)
}
export{
    cancelMoive,
    wxPay,
    aliPay
}