<template>
  <div class="item" v-if="food.name">
    <div class="title">
      <div class="left">{{ food.name }}</div>
      <div class="right" v-if="food.orderStatus == 0">待付款</div>
      <div class="right" v-else-if="food.orderStatus == 10">已作废</div>
      <div class="right" v-else-if="['1', '2', '3', '4'].includes(food.orderStatus)">
        待收货
      </div>
      <div class="right" v-else-if="food.orderStatus == 20">待评价</div>
      <div class="right" v-else-if="food.orderStatus == 21">已评价</div>
      <div class="right" v-else-if="
          ['9', '11', '12'].includes(food.orderStatus) ||
            food.officeApplyStatus == 1
        ">
        已退款
      </div>
    </div>
    <div class="good-info" @click="$router.push({path:'/takeOutOrderDetail',query:{id:food.id}})">
      <div class="imgs" v-for="item  in food.takeoutMyOrderGoodsVOList" :key="item.id">
        <img :src="item.goodsUrl[0]" alt="" />
        <div class="good-name">
          {{ item.goodsName }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <div class="left">下单时间：{{ food.createTime }}</div>
      <div class="right">
        合计：
        <div>
          ￥<span>{{ food.payFee }}</span>
        </div>
      </div>
    </div>
    <!-- 退款成功/失败原因 -->
    <div v-if="false"
      style="width: 100%;height: 31px;background-color: #F6F6F6 ;border-radius: 10px;margin-top: 5px;padding: 7px 20px;box-sizing: border-box;">
      {{'退款成功：退款成功 ¥ 46.80'}}
    </div>
    <!-- 按钮 -->
    <div class="btns">
      <!--  未付款删除订单 -->
      <div class="btn1" v-if="food.orderStatus == 0" @click="cancelOrder">
        取消订单
      </div>
      <!-- 付款后退款申请 -->
      <div class="btn1" v-if="food.orderStatus.orNotShown == '0'"
        @click="$router.push({path:'afterSale',params:{id:food.id}})">
        取消订单
      </div>

      <a class="btn1" v-if="food.orderStatus.orNotShown == '1'" href="tel:400-80008006">
        申请售后
      </a>
      <div class="btn1" v-if="food.orderStatus == 20" @click="$router.push({path:'/evaluateShop',query:{id:food.id}})">
        评论</div>
      <div class="btn2" v-if="food.orderStatus == 0" @click="Pay">付款</div>
      <!-- 退款详情 -->
      <div class="btn2" v-if="food.officeApplyStatus!=0" @click="
            $router.push({ path: '/refundDetail', query: { shopId: food.shopId,session:$route.query.session } })
          ">
        退款详情
      </div>
      <!-- 再来一单 -->
      <div class="btn2" v-if="food.orderStatus - 0" @click="
          $router.push({ path: '/shopList', query: { shopId: food.shopId,session:$route.query.session } })
        ">
        再来一单
      </div>
    </div>
  </div>
</template>

<script>
  import { foodPay, cancelNotPayOrder } from "../../api/takeOrderList";


  export default {
    props: ["food"],
    mounted() {
      console.log(this.food)
    },
    methods: {
      Pay() {
        foodPay(this.qs.stringify({ orderId: (this.food.id + "").trim() })).then(
          (res) => {
            console.log(typeof res.data.data == "string")
            if (res.data.code == 0) {
              if (typeof res.data.data == "string") {
                this.openPay(res.data, "2");
                return;
              }
              this.openPay(res, '1');
              return;
            }
            this.$toast(res.data.msg);
          }
        );
      },

      cancelOrder() {
        cancelNotPayOrder(this.qs.stringify({ id: this.food.id })).then((res) => {
          if (res.data.code == 0) {
            this.$toast.success("取消成功");
            this.food.name = false;
          } else {
            this.$toast.fail("取消失败");


          }
        });
      },
    },
  };
</script>

<style lang="less" scoped>
  .item {

    .good-info::-webkit-scrollbar {
      display: none;
    }

    .good-info {
      display: flex;
      overflow: auto;
      box-sizing: border-box;


      img {
        height: 50px;
        width: 85px;
        margin-right: 0.266667rem;


      }

      .imgs {
        width: 100px;
        flex-shrink: 0;

        .good-name {
          color: #999999;
          font-size: 12px;
          transform: scale(0.83);
        }
      }

      .good-box {
        font-size: 0.32rem;
        color: #333;

        .name {
          font-weight: 500;
          margin-bottom: 0.26666rem;
        }

      }
    }

  }

  .btn1 {

    width: 2.24rem;
    height: 0.72rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>