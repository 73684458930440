<template>
  <div class="order-center" >
    <!-- <div class="head">
      <img src="../../assets/img/commoditySpike/back.png" alt="" @click="$goBack()"/>
            <div class="head-box">
                        <div class="warp" @click="rollTop()">
                        
                          <div  >订单管理</div>
                    
                        </div>
              
            </div>
    </div> -->
    <Head :type="0"> 
      <template #title>
        <div class="tab-head" @click="rollTop" >
        <div    :class="{'active-tab':activeTab == 0}"  @click="changeTab(0)">订单管理</div>
        <div :class="{'active-tab':activeTab == 1}" @click="changeTab(1)"> 外卖 </div>
        </div>
      </template>
    </Head>
    <div class="nav">
    

<div class="list" ref="listLeft">
<div class="border" :style="{left:left+'px'}">

</div>
    <div class="list-box">
        <div  v-for="item,index in tabList " :key="item+index" @click="showIndex(index)" :class="['list-item', {'color':index-type==0}]" ref="listItem">{{item.title}}</div>
    </div>
</div>


      </div>
      
          <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" ref="scrollList"  >

        <div class="data"   v-if="activeTab == 0">
                    
          <ul v-for="item,index in orderData" :key="item.id+index"  >
          
              <!-- 酒店 -->
            <hotel      :hotel="item" :num="type"  :number="index"   :payType="payType"  v-if="item.hotelStatus!=null" @onShow="onShow"   />
            
              <!-- 自营商城 -->
            <mall-goods :mall="item"  :type="type"  :index="index"   :payType="payType"    @onShow="onShow"       v-else-if="  item.type=='4'"/>
           


              <!-- 电影票 -->
            <moive      :movie="item" :type="type"   :index="index"   :payType="payType"   @onShow="onShow"    v-else-if="item.movieName!=null"/>
            
              <!-- 吃喝玩乐 -->
            <leisure    :data='item' :type="type"   :index="index"   @onShow="onShow"   v-else-if="item.type==='5'"/>


              <!-- 家政 -->
            <keep       :keep="item" :type="type"   :index="index" @onShow="onShow" v-else-if="item.type==='6'" />

              <!-- 交通方式 -->
            <travel     :data="item" :type="type"   :index="index"  @onShow="onShow" @del="delOne" v-else-if="item.startStation"  />

               <!-- 外部商城 -->
            <external   :data="item"       @onShow="onShow"   v-else-if="['1','2','3'].includes(item.type)" />
          </ul>
          
            </div>
            <div class="data" v-if="activeTab == 1">
             <ul v-for="item,index in orderData" :key="item.id+index"  >


                <food :index="index"  :type="activeTab" :food="item"></food>
              </ul>

            </div>
           </van-list>

        <van-action-sheet v-model="show" title="支付">

                  <div class="content">
                  <van-radio-group v-model="payType">

        <van-cell-group>

          <van-cell clickable @click="payType = '1'">
                  <template #title>
                      <div style="display:flex;align-items:center;">
                         <img class="img" src="../../assets/img/weChat-icon.png" alt />
                            <span class="custom-title">微信</span>
                      </div>
                           

                  </template>

                  <template #right-icon>
                             <van-radio name="1" />
                  </template>
          </van-cell>
          <span class="line"></span>
          <van-cell clickable @click="payType = '2'">
            <template #title>
              <div style="display:flex;align-items:center;">  <img class="img" src="../../assets/img/alipay-icon.png" alt />
              <span class="custom-title">支付宝</span>  </div>
            
            </template>
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      <div class="pay" @click="pay()">支付</div>
          </div>
          
        </van-action-sheet>
    </div>
    
  
</template>

<script>
import '../../assets/css/order.less'

import {hotelPay} from '../../common/hotelPay'
import {moivePay} from '../../api/movie/pay'
import {mallPay}  from "../../common/mallPay" //自营商城支付
import  moive     from './film' 
import external   from "./externalOrder.vue"
import mallGoods  from "./mallGoods.vue"
import leisure    from './leisure.vue'
import hotel      from './hotel.vue'
import travel     from './travel.vue'
import keep       from "./HouseKeeping.vue"
import food       from './food.vue'
import { tPay}    from '../../common/travelPay'
import smoothscroll          from 'smoothscroll-polyfill'// 滚动动画
import {orders} from "../../api/ordersCenter/index"
import Head from '../../components/head/index.vue'
import { orderTakeList} from '../../api/takeOrderList'
export default {
  name:'order',

  data() {
    return {
      // active      : 0,
      activeValue : 0,
      page        : 1,
      size        : 20,
      orderData   : [],
      type        : 0,
      loading     : false,
      finished    : true,

      show        : false,
      payType     : '1',
      name        : '',
      orderId     : '',
      price       : 0,
      left        : 0,
      sessionSt   : null,

      activeTab   : 0,
    
        list: [
        {
          title: "全部",
          value:null,
          isShow:[0,1]
        },
        {
          title: "待付款",
          value:0,
          isShow : [0,1]
        },
        {
          title: "待发货",
          value:1,
          isShow:[0]

        },
        {
          title: "待收货",
          value:2,
          isShow:[0,1]

        },
        {
          title: "待使用",
          value:3,
          isShow:[0]

        },
        {
          title: "待评价",
          value:4,
          isShow:[0,1]

        },
        {
          title: "退款/售后",
          value:5,
          isShow:[0,1]

        },
      ],


    };
  },

  

  created(){
  window.closePage=this.closePage
  smoothscroll.polyfill()
      
    let session   =  this.$route.query.session 
    if(session){ 
      this.$store.commit('setSession',session)

    }

      this.sessionSt = JSON.parse(sessionStorage.getItem('pageInfo'))??null

                if(this.sessionSt!=null){ 
                  this.activeTab = this.sessionSt.tab
                  sessionStorage.removeItem('pageInfo')

                this.showIndex(parseInt(this.sessionSt.type) )


            }else{ 

                this.getOrderS()

            }
    
      
  },
computed:{ 

  tabList:function(){ 
   return this.list.filter( item => item.isShow.includes(this.activeTab))
    


  }

},
  methods: {

 showIndex(index){ 

            this.type=index

            this.page=1

            this.orderData=[]

            this.sessionSt!=null ? this.applyOrder(0,this.sessionSt.page)   :  this.applyOrder()

               
            },

  changaStatus(index,attribute,status){
        
        if(this.type){ 

          this.$set(this.orderData[index],attribute,'')
          return
        }

        this.$set(this.orderData[index],attribute,status)

  },
  
applyOrder( value , page){ 

  if(this.activeTab == 0){ 

    this.getOrderS(value,page)
    return
  }
  this.getTakeList()
},

getTakeList(form,pageSize=1){ 

orderTakeList(this.qs.stringify({
      current:this.page,  // 分页的页数

          size:this.size*pageSize, // 分页的长度   pageSize保存着页面离开时的 page的值

          state:this.tabList[this.type].value   // type 用来获取不同状态下的订单
})).then(res => { 

        if(res.data.code == 0){ 

            this.orderData= [...this.orderData,...res.data.data.records]

        }


      if(!res.data.data){ 
        throw new Error('请求错误')
      }
        if(res.data.data.records.length< this.size){ 

            this.finished =true

        }else{ 
          this.finished = false
        }
  
}).catch( (err) => { 
  console.log(err)
  this.finished = true 
}).finally( ()=> { 
  this.loading = false
})


},


  // 获取订单列表
   getOrderS(form,pageSize=1){
     
     // 判断 是否需要传status字段
    
    //  if(this.activeTab == 1) debugger

     if(this.list[this.type - 0].value !=null){

     orders({

          current:this.page,  // 分页的页数

          size:this.size*pageSize, // 分页的长度   pageSize保存着页面离开时的 page的值

          type:this.list[this.type].value +''  // type 用来获取不同状态下的订单

     }).then(res=>{

           
              if(form===0){

                this.orderData=[]

              }
              
             

              this.orderData= [...this.orderData,...res.data.data.records]
            
              
            if(this.orderData.length-res.data.data.total==0){

              this.finished=true

            }else{ 

             this.finished=false
         
       }

              if(this.sessionSt!=null){ 

              this.page=this.sessionSt.page

                            this.$nextTick(()=>{ 
                           
                          document.querySelector('.van-list').scrollTo(0, parseInt(this.sessionSt.scrollTop)  )
                           
                           this.$refs.listLeft.scrollTo(parseInt(this.sessionSt.scrollLeft),0)
                            
                           this.sessionSt=null

                              });

                              }

            this.loading=false

          })

     }else{

        orders({

       current:this.page,

       size:this.size*pageSize,

     }).then(res=>{

     
       if(res.data.code===0){
       
    
          this.orderData= [...this.orderData,...res.data.data.records]
       }

    
       if(this.orderData.length-res.data.data.total==0){

           this.finished=true

       }else{ 

           this.finished=false
         
       }
        if(this.sessionSt!=null){ 

        this.page=this.sessionSt.page

        this.$nextTick(()=>{ 

           document.querySelector('.van-list').scrollTo(0, parseInt(this.sessionSt.scrollTop)  )
                      
           this.$refs.listLeft.scrollTo(parseInt(this.sessionSt.scrollLeft),0)
                      
            this.sessionSt=null

          });
                       
                       }

       this.loading=false

     })
     }

   },

     

      //上拉加载
        onLoad(){
            
              this.loading = true
              this.page++
              this.applyOrder()


        },

        // 显示支付面板
        onShow(data){
          
            
              this.name=data.name
              this.orderId=data.orderId
              this.price=data.price
              this.show=true
             
        },

              //隐藏
        hideShow(){
          this.show = false
        },

    // 支付方法
     pay(){
       
       this.show=false
      
      if(this.name==='mall'){
      
         //自营商城
          mallPay(this.orderId,this.payType)

      }


      if(this.name==='film'){
          //电影票支付
           moivePay(this.orderId,this.payType,this.price )

      }

      if(this.name==='hotel'){ 
         //酒店支付
        hotelPay(this.orderId,this.payType,this.price )
      }
         if(this.name=='travel')[ 
           tPay(this.orderId,this.payType,this.price)
         ]

    },
   
   //支付回调
   closePage(){
        // 判断是否支付成功   1为支付失败
    

    this.sessionSt = {page      :this.page,
                      type      :this.type,
                      scrollTop :document.querySelector('.van-list').scrollTop,
                      scrollLeft:this.$refs.leftList.scrollLeft}
    
                  this.showIndex(this.sessionSt.type )
               


    },

   
    rollTop(){ 


                                 document.querySelector('.van-list').scrollTo({  top:0, behavior:'smooth'})
                    
              },

    
    delOne(index){ 

        this.$delete(this.orderData, index);
    },

    changeTab( tab){ 
      if(tab == this.activeTab) return
      this.activeTab = tab
      this.page = 1
      this.finished = true
      this.showIndex(0)
    }
   
  },
  watch:{ 

      type:function (){ 
        

        
        
                    this.$refs.listLeft.scrollTo({ 
                            left:(this.$refs.listItem[this.type].offsetLeft - (document.body.getBoundingClientRect().width/2) +
                                                                this.$refs.listItem[this.type].getBoundingClientRect().width/2),
                            top:0,
                             behavior:'smooth'
                        })
                 this.left=this.$refs.listItem[this.type].offsetLeft +(this.$refs.listItem[this.type].offsetWidth)/2-(0.133333*window.screen.width/7)

      
      }
      
  },
  

      mounted() {


        
                // 将支付回调挂在 window上
              
              
                //设置根字体的方法
                this.setRem();
              

                // 监控是否横屏
                window.addEventListener("orientationchange", this.setRem);
              
              // 监控窗口大小是否改变
                window.addEventListener("resize", this.setRem);
    
                this.left=this.$refs.listItem[this.type].offsetLeft +(this.$refs.listItem[this.type].offsetWidth)/2-(0.133333*window.screen.width/7)
      },


      destroyed() {
        
                 document.documentElement.style.fontSize = "16px";
                 
      },
  components:{
    external,   //第三方商城
    mallGoods,  //自营商城
    leisure,    //休闲玩乐
    hotel,      // 酒店
    moive,      //电影
    keep,       //家政
    travel,     //交通出行
    Head,        // 头部
    food   // 外卖
   
  },
   beforeRouteLeave(to ,from ,next){

          
                sessionStorage.setItem('pageInfo',JSON.stringify({ page:this.page,type:this.type+'',scrollTop:document.querySelector('.van-list').scrollTop+'',scrollLeft:this.$refs.listLeft.scrollLeft+'' ,tab: this.activeTab }))
                   
                   next()

            },



       
}
</script>

<style lang="less" scoped>
/deep/  .van-list::-webkit-scrollbar{ 
      display: none !important;
    }

</style>
